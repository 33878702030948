import { Alert, Button, Divider, Spin } from 'antd';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getLayerById, createNewZone } from 'services/message.service';
import EditLayerModal from './EditLayerModal';
import useModal from 'hooks/useModal';
import DeleteLayerModal from './DeleteLayerModal';
import DeleteZoneModal from './DeleteZoneModal';
import { useNavigate } from 'react-router-dom';
import MapComponent from './MapComponent';
import { DeleteOutlined } from '@ant-design/icons';
import { Card, CustomNotification } from 'components/basic';

interface Zone {
    id: string;
    name: string;
    created_at: string;
    created_by: string;
    updated_at: string;
    geojson: {
        type: string;
        features: Array<any>;
    };
}

interface Zone {
    id: string;
    name: string;
    created_at: string;
    created_by: string;
    updated_at: string;
    geojson: {
        type: string;
        features: Array<any>;
    };
}

const ZoneDetailPage = () => {
    const navigate = useNavigate();
    const editLayerModal = useModal();
    const deleteLayerModal = useModal();
    const deleteZoneModal = useModal();

    const [zoneData, setZoneData] = useState<Zone[]>([]);
    const [selectedZoneId, setSelectedZoneId] = useState<number | null>(null);
    const [zoneName, setZoneName] = useState<number | null>(null);
    const [name, setName] = useState<number | null>(null);
    const [mapKey, setMapKey] = useState(0);

    let { id } = useParams();
    const { request, result, loading } = useApi({
        api: getLayerById,
    });

    const {
        request: createZone,
        result: resultZone,
        loading: loadingZone,
        error: errorCreateZone,
    } = useApi({
        api: createNewZone,
    });

    useMount(() => {
        fetchLayerById(id + '');
    });

    const fetchLayerById = useCallback(
        async (layerId: string) => {
            try {
                const response = await request({ id: layerId || id });
                setZoneData(response?.data?.zones || []);
            } catch (error) {
                throw error;
            }
        },
        [request, id],
    );

    const handleOnEditModal = useCallback(() => {
        editLayerModal.show();
    }, [editLayerModal]);

    const handleOnDeleteModal = useCallback((layerName: any) => {
        setName(layerName)
        deleteLayerModal.show();
    }, [deleteLayerModal]);

    const [polygonCoords, setPolygonCoords] = useState<google.maps.LatLng[] | null>([]);
    const [selectedPlaceName, setSelectedPlaceName] = useState<string>('Unknown Location');

    const handlePolygonUpdate = (coords: google.maps.LatLng[] | null) => {
        setPolygonCoords(coords);
    };

    const handlePlaceNameUpdate = (name: string) => {
        setSelectedPlaceName(name);
    };

    const saveGeoJsonData = useCallback(
        async (geoJsonData: any) => {
            const res = await createZone(geoJsonData);
            if (!res.error) {
                CustomNotification({
                    type: 'success',
                    message: 'Success',
                    description: 'New zone successfully created.',
                });
                fetchLayerById(id + '');
                setPolygonCoords(null);
                // Increment mapKey to reset the map
                setMapKey((prevKey) => prevKey + 1);
            } else {
                CustomNotification({
                    type: 'error',
                    message: 'Error',
                    description: <p className="capitalize">{res.error.message}</p>,
                });
            }
        },
        [createZone, fetchLayerById, id]
    );
    const handleSaveZone = useCallback(() => {
        const cleanName = selectedPlaceName.replace(/[^a-zA-Z0-9]/g, '');

        const body = {
            id: id,
            name: cleanName,
            geojson: {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {
                            name: cleanName,
                        },
                        geometry: {
                            type: 'Polygon',
                            coordinates: [polygonCoords?.map(coord => [coord.lng(), coord.lat()])],
                        },
                    },
                ],
            },
            properties: {
                name: cleanName,
            },
        };

        saveGeoJsonData(body);
    }, [polygonCoords, selectedPlaceName, id, saveGeoJsonData]);

    useEffect(() => {
        if (zoneData.length > 0) {
            console.log('Zones are present:', zoneData);
        } else {
            console.log('No zones are present.');
        }
    }, [zoneData]);

    const handleOnDeleteZoneModal = useCallback(
        (zoneId: any, name: any) => {
            setSelectedZoneId(zoneId);
            setZoneName(name)
            deleteZoneModal.show();
        },
        [deleteZoneModal],
    );
    const handleZoneClick = (zoneId: string) => {
        const layerId = result?.data?.id;
        if (layerId) {
            navigate(`/zones/edit/${layerId}/${zoneId}`);
        } else {
            console.error('Layer ID is missing');
        }
    };
    return (
        <div className="flex">
            <div className="h-screen w-1/4 bg-[#E6F7FF]">
                <div className="bg-[#40A9FF] p-6">
                    <Link className="text-white text-sm cursor-pointer hover:text-gray-200" to={'/zones'}>
                        ← Go Back
                    </Link>
                </div>
                {!loading ? (
                    <div>
                        <div className="flex justify-between p-6">
                            <div>
                                <span>Layer Name</span>
                                <h5 className="font-extrabold">{result?.data?.name}</h5>
                                <span>Description</span>
                                <h5>{result?.data?.description}</h5>
                            </div>
                            <div>
                                <Button onClick={() => handleOnEditModal()}>Edit</Button>
                            </div>
                        </div>
                        <Divider style={{ margin: '8px 0' }} />
                        <h5 className="pt-0 pb-2 p-6">Zone List</h5>
                        {zoneData?.length < 1 ? (
                            <>
                                <div className="m-6 mt-0">
                                    <Alert
                                        message="Search location and draw polygons to define the zone."
                                        type="info"
                                        showIcon
                                    />
                                </div>
                                <span className="m-6 mt-0 text-sm">Empty List</span>
                            </>
                        ) : (
                            <div className='h-80 overflow-auto	'>
                                <ul className="list-none p-6 pt-0">
                                    {zoneData.map(zone => (
                                        <React.Fragment key={zone.id}>
                                            <div className="flex justify-between items-center">
                                                <li
                                                    className='text-base cursor-pointer'
                                                onClick={() => handleZoneClick(zone.id)} // Navigate to the zone page on click
                                                >
                                                    {zone.name}
                                                </li>
                                                <DeleteOutlined
                                                    onClick={() => handleOnDeleteZoneModal(zone.id, zone.name)}
                                                    className="hover:text-red-500"
                                                />
                                            </div>
                                            <Divider style={{ margin: '8px 0' }} />
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ) : (
                    <Card className="mt-14">
                        <Spin />
                    </Card>
                )}
            </div>
            <div className="w-3/4">
                <div className="bg-white p-4 flex justify-end">
                    <Button onClick={() => handleOnDeleteModal(result?.data?.name)} className="bg-white mr-2">
                        Delete Layer
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => handleSaveZone()}
                        disabled={!polygonCoords || polygonCoords?.length === 0}
                    >
                        Save
                    </Button>
                </div>
                <div className="px-3">
                    <div className="m-2 ml-0 w-1/2">
                        <Alert
                            message="In order to draw polygon you need to click on map where you need zones"
                            type="warning"
                            showIcon
                        />
                    </div>
                    <MapComponent  key={mapKey} onPolygonUpdate={handlePolygonUpdate} onPlaceNameUpdate={handlePlaceNameUpdate} />
                </div>
            </div>
            <EditLayerModal
                modal={editLayerModal}
                onSuccess={() => {
                    fetchLayerById(id + '');
                }}
                name={result?.data?.name}
                description={result?.data?.description}
            />
            <DeleteLayerModal 
            name={name} 
            modal={deleteLayerModal}
             onSuccess={() => navigate('/zones')}
            />

            <DeleteZoneModal
                modal={deleteZoneModal}
                zoneId={selectedZoneId ?? ''}
                zoneName={zoneName}
                onSuccess={() => fetchLayerById(id || '')}
            />
        </div>
    );
};

export default ZoneDetailPage;
