import React, { useState, useCallback, useEffect, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { Button } from 'antd';
import { CustomNotification } from 'components/basic';
import { Input } from 'components/basic';

const mapContainerStyle = {
    width: '100%',
    height: '85vh',
};

const defaultCenter = {
    lat: 14.6091,
    lng: 121.0223,
};

const libraries: any[] = ['places'];
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

interface MapComponentProps {
    onPolygonUpdate: (coords: google.maps.LatLng[] | null) => void;
    onPlaceNameUpdate: (name: string) => void;
    initialGeojson?: any;
}

const MapComponent: React.FC<MapComponentProps> = ({ onPolygonUpdate, onPlaceNameUpdate, initialGeojson }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
    const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
    const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng | null>(null);
    const [mapKey, setMapKey] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [polygonPath, setPolygonPath] = useState<google.maps.LatLng[] | null>(null);

    const selectedPlaceRef = useRef<google.maps.places.PlaceResult | null>(null);

    useEffect(() => {
        if (initialGeojson?.features[0]?.geometry?.coordinates[0]) {
            const defaultGeoJsonData = initialGeojson.features[0].geometry.coordinates[0];
            if (defaultGeoJsonData) {
                const staticLatLngPath = defaultGeoJsonData.map(
                    (coord: [number, number]) => new google.maps.LatLng(coord[1], coord[0]),
                );
                setPolygonPath(staticLatLngPath);
            }
        } else {
            setPolygonPath(null); // Set to null if there's no valid GeoJSON data
        }
    }, [initialGeojson]);

    useEffect(() => {
        if (map && polygonPath && polygonPath.length > 0) {
            const newPolygon = new google.maps.Polygon({
                paths: polygonPath,
                strokeColor: '#1890FF',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#91D5FF8F',
                fillOpacity: 0.35,
                editable: true,
                draggable: false,
            });
            newPolygon.setMap(map);
            setPolygon(newPolygon);
            onPolygonUpdate(polygonPath);

            // Add event listeners for editing the polygon
            google.maps.event.addListener(newPolygon.getPath(), 'set_at', () => {
                const updatedPath = newPolygon.getPath().getArray();
                setPolygonPath(updatedPath);
                onPolygonUpdate(updatedPath); // Update the parent component with the new path
            });

            google.maps.event.addListener(newPolygon.getPath(), 'insert_at', () => {
                const updatedPath = newPolygon.getPath().getArray();
                setPolygonPath(updatedPath);
                onPolygonUpdate(updatedPath); // Update the parent component with the new path
            });

            // Only zoom if this is an initial load from the initialGeojson
            if (initialGeojson) {
                const bounds = new google.maps.LatLngBounds();
                polygonPath.forEach(point => {
                    bounds.extend(point);
                });
                map.fitBounds(bounds); // Zoom to fit the polygon
            }
        }
    }, [map, polygonPath, onPolygonUpdate, initialGeojson]);

    const center =
        polygonPath && polygonPath.length > 0
            ? { lat: polygonPath[0].lat(), lng: polygonPath[0].lng() }
            : defaultCenter;

    const onMapLoad = useCallback(
        (map: google.maps.Map) => {
            setMap(map);
            setAutocompleteService(new google.maps.places.AutocompleteService());
            setPlacesService(new google.maps.places.PlacesService(map));

            map.addListener('click', (event: google.maps.MapMouseEvent) => {
                if (event.latLng) {
                    if (!selectedPlaceRef.current) {
                        CustomNotification({
                            type: 'error',
                            message: 'Location Required',
                            description: 'Please select a location before drawing a polygon.',
                        });
                        return; // Exit if no location is selected
                    }

                    // Proceed with polygon drawing if location is selected
                    setPolygonPath(prev => {
                        const newPath = [...(prev || []), event.latLng].filter(
                            (point): point is google.maps.LatLng => point !== null,
                        );

                        if (newPath.length > 0) {
                            const newPolygon = new google.maps.Polygon({
                                paths: newPath,
                                strokeColor: '#1890FF',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: '#91D5FF8F',
                                fillOpacity: 0.35,
                                editable: true,
                                draggable: false,
                            });
                            newPolygon.setMap(map);
                            setPolygon(newPolygon);
                            onPolygonUpdate(newPath);
                        }
                        return newPath;
                    });
                } else if (polygon) {
                    CustomNotification({
                        type: 'error',
                        message: 'Polygon already exists!',
                        description: 'Please clear the current polygon before drawing a new one.',
                    });
                }
            });
        },
        [polygon, onPolygonUpdate], // Remove selectedPlace from dependencies and use ref
    );

    const onPlaceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        setInputValue(input);
        if (autocompleteService) {
            autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    setPredictions(predictions || []);
                }
            });
        }
    };

    const onSelectPlace = (placeId: string) => {
        if (placesService) {
            placesService.getDetails({ placeId }, (place, status) => {
                if (
                    status === google.maps.places.PlacesServiceStatus.OK &&
                    place &&
                    place.geometry &&
                    place.geometry.location
                ) {
                    // Update selectedPlaceRef instead of state
                    selectedPlaceRef.current = place;
                    setMarkerPosition(place.geometry.location);
                    setPredictions([]);
                    setInputValue(place.name || 'Unknown Place');

                    if (map) {
                        const location = place.geometry.location;
                        if (location) {
                            map.panTo(location);
                            map.setZoom(14);
                        }
                    }
                    onPlaceNameUpdate(place.name || 'Unknown Place');
                }
            });
        }
    };

    const clearPolygon = () => {
        if (polygon) {
            polygon.setMap(null);
            setPolygon(null);
            setPolygonPath(null); // Set to null
            setMapKey(prevKey => prevKey + 1);
            setInputValue('');
            onPolygonUpdate(null);
        }
    };
    const handleSearch = () => {
        const input = inputValue;
        if (autocompleteService) {
            autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
                    setPredictions(predictions);
                    const placeId = predictions[0].place_id;
                    onSelectPlace(placeId);
                } else {
                    CustomNotification({
                        type: 'error',
                        message: 'No results found',
                        description: 'Please enter a valid location.',
                    });
                }
            });
        }
    };

    if (!isLoaded) return <div>Loading...</div>;
    const mapOptions = {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
    };

    return (
        <div style={{ position: 'relative' }}>
            <GoogleMap
                key={mapKey}
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={10}
                options={mapOptions}
                onLoad={onMapLoad}
            >
                <Marker position={center} />
                {markerPosition && <Marker position={markerPosition} />}
            </GoogleMap>

            <div style={{ position: 'absolute', top: '-93px', left: '0px', zIndex: 1 }}>
                <div className="flex">
                    <Input
                        type="text"
                        placeholder="Search Location and add to zone"
                        style={{ width: '300px', padding: '5px' }}
                        className="rounded border"
                        value={inputValue}
                        onChange={onPlaceChanged}
                        onClick={() => {
                            const input = inputValue;
                            if (autocompleteService) {
                                autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
                                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                                        setPredictions(predictions || []);
                                    }
                                });
                            }
                        }}
                    />
                    <div>
                        <img
                            src="/images/search-Icon.png"
                            alt="Search"
                            onClick={handleSearch}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </div>

                {polygon ? (
                    <Button
                        onClick={clearPolygon}
                        style={{
                            marginLeft: '0.5rem',
                            padding: '5px 15px',
                            height: 'auto',
                            position: 'absolute',
                            top: '120px',
                            left: '20px',
                            background: '#0084B0',
                            color: 'white',
                        }}
                    >
                        Clear Polygon
                    </Button>
                ) : null}
                {inputValue.length > 0 && predictions.length > 0 && (
                    <div className="bg-white h-auto overflow-auto py-2 rounded drop-shadow w-[300px]">
                        {predictions.map(prediction => (
                            <div
                                className="hover:bg-slate-100 px-4 py-1"
                                key={prediction.place_id}
                                onClick={() => {
                                    setInputValue(prediction.description); // Set input value here
                                    onSelectPlace(prediction.place_id);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {prediction.description}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MapComponent;
